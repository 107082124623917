import React, { useEffect, useState } from 'react';

import { getYearsSinceOfficeBirth } from '../../utils/utils';

const Team = () => {
  const [isJqueryInitiated, setIsJqueryInitiated] = useState(false);

  function initiateJquerySliders() {
    ['CL', 'JPL', 'MHL', 'PML'].forEach((initials) => {
      $(`.btn${initials}`).click(function () {
        /* slide */
        $(`div#${initials}Bio`).slideToggle();
        /* get sign */
        var isDisplayed = document.getElementById(`sign${initials}`).innerHTML;
        /* change sign */
        document.getElementById(`sign${initials}`).innerHTML =
          isDisplayed == '+' ? '-' : '+';
      });
    });
  }

  useEffect(() => {
    if (!isJqueryInitiated) {
      initiateJquerySliders();
      setIsJqueryInitiated(true);
    }
  });

  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Our Team</h1>
      </div>

      <div id="img-wrap">
        <img
          src="/img/lemire-equipe-large.jpg"
          alt="Our Team"
          title="Our Team"
        />
      </div>

      <div id="info">
        <div id="equipe">
          {/* 1er avocat */}
          <div itemScope itemType="https://schema.org/Person">
            <ul className="equipeListe">
              <li>
                <img
                  src="/img/claude_lemire.jpg"
                  itemProp="image"
                  className="btnCL"
                  height="185"
                  width="130"
                  alt="Claude Lemire"
                />
              </li>

              <ul>
                <h2>
                  <span itemProp="name">
                    <span className="accent5">Claude Lemire</span>
                  </span>
                </h2>
              </ul>

              <li>
                <div className="equipeTitre">
                  <h3>
                    <span className="accent2">Title</span>
                  </h3>
                  <p>
                    <span itemProp="jobTitle">Partner</span>
                  </p>
                </div>
              </li>

              <li>
                <div className="equipeTel">
                  <ul className="equipeData">
                    <li>
                      <h3>
                        <span className="accent2">Direct Line</span>
                      </h3>
                      <p>
                        <a href="tel:+14509661055,1">
                          <span itemProp="telephone">(450) 966-1055</span> #1
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <div className="equipeMail">
                  <ul>
                    <li>
                      <h3>
                        <span className="accent2">E-Mail</span>
                      </h3>
                      <p>
                        <a
                          href="mailto:clemire@lemireavocat.com"
                          itemProp="email"
                        >
                          clemire@lemireavocat.com
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <a id="equipe" className="scroll btnCL">
              Profile <span id="signCL">+</span>
            </a>
            <div id="CLBio">
              <div
                itemProp="description"
                align="justify"
                className="equipeDesc"
              >
                <p>
                  Mr. Lemire was called to the Barreau du Québec in 1975. His
                  area of expertise includes litigation, family, real estate and
                  civil law.
                </p>
                <p>
                  During his{' '}
                  <span id="anneesExp">{getYearsSinceOfficeBirth()}</span> years
                  dedicated to the practice of law as a litigator in private
                  practice, Mr. Lemire has appeared before various courts in
                  civil, administrative and criminal litigation.
                </p>
                <p>
                  Throughout his career, Mr. Claude Lemire has been very active
                  in the Barreau du Québec and has been nominated president of
                  the Laurentides-Lanaudière region. He is also a member of the
                  Complaints Review Committee of the Syndic's Office of the
                  Barreau du Québec.
                </p>
              </div>
            </div>
            <hr />
          </div>

          {/* 2e avocat */}
          <div itemScope itemType="https://schema.org/Person">
            <ul className="equipeListe">
              <li>
                <img
                  src="/img/jean-philippe_lemire.jpg"
                  itemProp="image"
                  className="btnJPL"
                  height="185"
                  width="130"
                  alt="Jean-Philippe Lemire"
                />
              </li>
              <ul>
                <h2>
                  <span itemProp="name">
                    <span className="accent5">Jean-Philippe Lemire</span>
                  </span>
                </h2>
              </ul>
              <li>
                <div className="equipeTitre">
                  <h3>
                    <span className="accent2">Title</span>
                  </h3>
                  <p>
                    <span itemProp="jobTitle">Partner</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="equipeTel">
                  <ul className="equipeData">
                    <li>
                      <h3>
                        <span className="accent2">Direct Line</span>
                      </h3>
                      <p>
                        <a href="tel:+14509661055,3">
                          <span itemProp="telephone">(450) 966-1055</span> #3
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="equipeMail">
                  <ul>
                    <li>
                      <h3>
                        <span className="accent2">E-Mail</span>
                      </h3>
                      <p>
                        <a
                          href="mailto:jplemire@lemireavocat.com"
                          itemProp="email"
                        >
                          jplemire@lemireavocat.com
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <a id="equipe" className="scroll btnJPL">
              Profile <span id="signJPL">+</span>
            </a>
            <div id="JPLBio">
              <div
                itemProp="description"
                align="justify"
                className="equipeDesc"
              >
                <p>
                  The practice of Jean-Philippe Lemire encompasses various
                  aspects of law in the areas of construction, real estate,
                  insurance and civil liability. He also redacts legal opinions
                  on various topics.
                </p>
                <p>
                  M<sup>e</sup> Lemire has worked in various litigation matters,
                  including defamation, hidden construction defects, contractual
                  breaches and bodily injury.
                </p>
                <p>
                  He joined the firm in 2009 as a lawyer after having worked as
                  a student and an intern.
                </p>
              </div>
            </div>
            <hr />
          </div>

          {/* 3e avocate */}
          <div itemScope itemType="https://schema.org/Person">
            <ul className="equipeListe">
              <li>
                <img
                  src="/img/marie-helene_lemire.jpg"
                  itemProp="image"
                  className="btnMHL"
                  height="185"
                  width="130"
                  alt="Marie-Hélène Lemire"
                />
              </li>
              <ul>
                <h2>
                  <span itemProp="name">
                    <span className="accent5">Marie-Hélène Lemire</span>
                  </span>
                </h2>
              </ul>
              <li>
                <div className="equipeTitre">
                  <h3>
                    <span className="accent2">Title</span>
                  </h3>
                  <p>
                    <span itemProp="jobTitle">Partner</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="equipeTel">
                  <ul className="equipeData">
                    <li>
                      <h3>
                        <span className="accent2">Direct Line</span>
                      </h3>
                      <p>
                        <a href="tel:+14509661055">
                          <span itemProp="telephone">(450) 966-1055</span>
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="equipeMail">
                  <ul>
                    <li>
                      <h3>
                        <span className="accent2">E-Mail</span>
                      </h3>
                      <p>
                        <a
                          href="mailto:mhlemire@lemireavocat.com"
                          itemProp="email"
                        >
                          mhlemire@lemireavocat.com
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <a id="equipe" className="scroll btnMHL">
              Profile <span id="signMHL">+</span>
            </a>
            <div id="MHLBio">
              <div
                itemProp="description"
                align="justify"
                className="equipeDesc"
              >
                <p>
                  In 2005, Marie-Hélène Lemire joined Claude Lemire and founded
                  four years later the law firm Lemire Lemire avocats LLP.
                </p>
                <p>
                  Her practice encompasses all aspects of family, civil and
                  commercial litigation.
                </p>
                <p>
                  M<sup>e</sup> Lemire has worked in various litigation matters,
                  including matters of child custody, divorce, division of
                  family residence, unjust enrichment, nonperformance of
                  contractual obligations and bodily injury.
                </p>
                <p>She also offers family mediation services.</p>
              </div>
            </div>
            <hr />
          </div>

          {/* 4e avocat */}
          <div itemScope itemType="https://schema.org/Person">
            <ul className="equipeListe">
              <li>
                <img
                  src="/img/pierre-marc_lemire.jpg"
                  itemProp="image"
                  className="btnPML"
                  height="185"
                  width="130"
                  alt="Pierre-Marc Lemire"
                />
              </li>
              <ul>
                <h2>
                  <span itemProp="name">
                    <span className="accent5">Pierre-Marc Lemire</span>
                  </span>
                </h2>
              </ul>
              <li>
                <div className="equipeTitre">
                  <h3>
                    <span className="accent2">Title</span>
                  </h3>
                  <p>
                    <span itemProp="jobTitle">Partner</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="equipeTel">
                  <ul className="equipeData">
                    <li>
                      <h3>
                        <span className="accent2">Direct Line</span>
                      </h3>
                      <p>
                        <a href="tel:+14509661055,2">
                          <span itemProp="telephone">(450) 966-1055</span> #2
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="equipeMail">
                  <ul>
                    <li>
                      <h3>
                        <span className="accent2">E-Mail</span>
                      </h3>
                      <p>
                        <a
                          href="mailto:pmlemire@lemireavocat.com"
                          itemProp="email"
                        >
                          pmlemire@lemireavocat.com
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <a id="equipe" className="scroll btnPML">
              Profile <span id="signPML">+</span>
            </a>
            <div id="PMLBio">
              <div
                itemProp="description"
                align="justify"
                className="equipeDesc"
              >
                <p>
                  Pierre-Marc Lemire joined the firm in 2019, after working for
                  more than 10 years in Montreal, namely in a national firm.
                </p>
                <p>
                  M<sup>e</sup> Lemire's practice is focused on commercial
                  litigation and financing services. More specifically, his
                  practice includes all aspects of banking law, bankruptcy,
                  insolvency and reorganization matters as well as construction
                  law.
                </p>
                <p>
                  He also acts in matters involving commercial leases,
                  shareholders disputes and estates and trusts litigations.
                </p>
                <p>
                  His expertise in these matters has led him to represent
                  financial institutions, trustees in bankruptcy as well as
                  creditors and debtors in a variety of mandates involving
                  secured transactions, hypothecary recourses, receiverships and
                  seizure before judgment, as well as pleading before the
                  Superior Court and the Québec Court of Appeal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Team.propTypes = {};

export default Team;
