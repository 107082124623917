import React from 'react';

import { PAGES } from '../../utils/utils';
import Team from '../../components/en/team';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const TeamPage = () => (
  <Layout lang="en" page={PAGES.EQUIPE}>
    <SEO title="Team" lang="en" />
    <Team />
  </Layout>
);

export default TeamPage;
